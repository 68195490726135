import React from "react";
import NET from "../../assets/.NET.pdf";
import HTMLCSS from "../../assets/HTML and CSS in depth.pdf";
import FrontEnd from "../../assets/Introduction to Front-End.pdf";
import VersionControl from "../../assets/Version Control certificate.pdf";
import ReactBa from "../../assets/React Basics.pdf";

const Certificates = () => {
  return (
    <div className="certificates">
      <a href={NET} download className="btn">
        .NET-Core Certificate
      </a>
      <a href={HTMLCSS} download className="btn">
        HTML & CSS Meta Certificate
      </a>
      <a href={FrontEnd} download className="btn">
        Intro to Front-End Meta Certificate
      </a>
      <a href={VersionControl} download className="btn">
        Version Control Meta Certificate
      </a>
      <a href={ReactBa} download className="btn">
        React-Basic Meta Certificate
      </a>
    </div>
  );
};

export default Certificates;
