import React from "react";
import "./about.css";
import Certificate from "./Certificate";
import Me from "../../assets/pic3.jpg";
import { FaAward } from "react-icons/fa";
import { MdOutlineCastForEducation } from "react-icons/md";
import { PiCertificate } from "react-icons/pi";

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />

              <h5>Experience</h5>
              <small>4+ years working as a Fullstack Developer </small>
            </article>
            <article className="about__card">
              <MdOutlineCastForEducation className="about__icon" />

              <h5>Education</h5>
              <small>
                <ul>
                  <li>-Bachelor of Software Engineering</li>
                  <li>-Master of Computer Engineering</li>
                </ul>
              </small>
            </article>
            <article className="about__card">
              <PiCertificate className="about__icon" />

              <h5>Certificates</h5>
              <small>
                5+ completed Certificates
                <Certificate />
              </small>
            </article>
          </div>

          <p>
            With over 4 years of professional experience in full-stack
            development, I’ve honed my skills in building scalable,
            user-friendly applications. My passion lies in solving complex
            problems and delivering solutions that drive real impact. Whether
            it's creating innovative features, leading development teams, or
            mentoring junior developers, I bring both technical and creative
            expertise to every project. I'm committed to continuous learning and
            improvement, always striving to stay ahead of the curve in an
            ever-evolving industry.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
