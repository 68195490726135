import React from "react";
import { BsLinkedin } from "react-icons/bs";

function Headersocials() {
  return (
    <div className="header__socials">
      <a href="http://linkedin.com" target="_blank">
        <BsLinkedin />
      </a>
    </div>
  );
}

export default Headersocials;
